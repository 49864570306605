// The names of files that are in the laravel project folder \public\files\conditions
const bedingungenLogistikVersicherung = 'Bedingungen Logistik Versicherung 4.0.pdf';
const BedingungenJuwelierwarenundValorenversicherung = 'Bedingungen Juwelierwaren- und Valorenversicherung.pdf';
const erklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung = 'Erklarungen und Hinweise zum Antrag auf Abschluss einer Versicherung.pdf';
const produktInformationsBlatt = 'Produkt-Informations-Blatt.pdf';
const ProduktInformationsblattJuwelierwarenValorenVersicherung = 'Produkt Informationsblatt Juwelierwaren Valoren Versicherung.pdf';
const cyberInformationsBlatt = 'Cyber-Informations-Blatt.pdf';
const flyerDeclarationManager = 'Flyer Declaration Manager.pdf';
const flyerFrachtfuhrerHaftungsversicherung = 'Flyer Frachtfuhrer-Haftungsversicherung.pdf';
const flyerSpeditionsHaftungsversicherung = 'Flyer Speditions-Haftungsversicherung.pdf';
const BedingungenTransportWarenversicherung = 'Bedingungen Transport-Warenversicherung.pdf';
const BedingungenWerkverkehrsversicherung = 'Bedingungen Werkverkehrsversicherung.pdf';
const CyberInformationsBlatt = 'Cyber-Informations-Blatt.pdf';
const TariffürdieVersicherungvonBijouterievaloren = 'Tarif für die Versicherung von Bijouterievaloren.pdf';
const ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung = 'Erklärungen und Hinweise zum Antrag auf Abschluss einer Versicherung.pdf';
const Flyer10GrundeFurEineEigeneWarentransportversicherung = 'Flyer 10 Gründe für eine eigene Warentransportversicherung.pdf';
const ProduktInformationsblattTransportWarenversicherung = 'Produkt Informationsblatt Transport-Warenversicherung.pdf';
const ProduktInformationsblattWerkverkehrsversicherung = 'Produkt Informationsblatt Werkverkehrsversicherung.pdf';
const ProduktvergleichSynopseWarenversicherungWerkverkehrsversicherung = 'Produktvergleich-Synopse-Warenversicherung-Werkverkehrsversicherung.pdf';
const BedingungenAusstellungsversicherung = 'Bedingungen Ausstellungsversicherung.pdf';
const ProduktInformationsblattAusstellungsversicherung = 'Produkt Informationsblatt Ausstellungsversicherung.pdf';
const BedingungenMusterkollektionsversicherung = 'Bedingungen Musterkollektionsversicherung.pdf';
const ProduktInformationsblattMusterkollektionsversicherung = 'Produkt Informationsblatt Musterkollektionsversicherung.pdf';
const BedingungenFirmenReisegepäckversicherung = 'Bedingungen Firmen-Reisegepäckversicherung.pdf';
const ProduktInformationsblattFirmenReisegepäckversicherung = 'Produkt Informationsblatt Firmen-Reisegepäckversicherung.pdf';
const ProduktsteckbriefAusstellungsversicherung = 'Produktsteckbrief Ausstellungsversicherung.pdf';
const ProduktsteckbriefJuwelierwarenUndValorenversicherung = 'Produktsteckbrief Juwelierwaren- und Valorenversicherung.pdf';
const ProduktsteckbriefTransportWarenversicherung = 'Produktsteckbrief Transport-Warenversicherung.pdf';
const ProduktsteckbriefWerkverkehrsversicherung = 'Produktsteckbrief Werkverkehrsversicherung.pdf';
const BedingungenLogistikVersicherung = 'Bedingungen Logistik Versicherung.pdf';
const BesondereBedingungenZoll = 'Besondere-Bedingungen-Zoll.pdf';
// const CyberInformationsBlatt = 'Cyber-Informations-Blatt.pdf';
const ErklarungenundHinweisezumAntragaufAbschlusseinerVersicherung = 'Erklarungen-und-Hinweise-zum-Antrag-auf-Abschluss-einer-Versicherung.pdf';
const FlyerDigitalesUnderwritingTransportDUTR = 'Flyer-Digitales-Underwriting-Transport_DU-TR.pdf';
const HighlightsLogistikVersicherung4 = 'Highlights-Logistik-Versicherung-4.0.pdf';
const PIBProduktInformationsBlattLogistikVersicherung4 = 'PIB-Produkt-Informations-Blatt-Logistik-Versicherung-4.0.pdf';

// For each type of insurance we export the necessary  files.
export default {
  FORWARDING_AGENT_INSURANCE: [
    {
      fileName: BedingungenTransportWarenversicherung,
    },
    {
      fileName: ProduktInformationsblattTransportWarenversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: ProduktvergleichSynopseWarenversicherungWerkverkehrsversicherung,
    },
    {
      fileName: ProduktsteckbriefTransportWarenversicherung,
    },
  ],
  OWN_GOODS_INSURANCE: [
    {
      fileName: BedingungenWerkverkehrsversicherung,
    },
    {
      fileName: ProduktInformationsblattWerkverkehrsversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: ProduktvergleichSynopseWarenversicherungWerkverkehrsversicherung,
    },
    {
      fileName: ProduktsteckbriefWerkverkehrsversicherung,
    },
  ],
  ANNUAL_INSURANCE: [
    {
      fileName: BedingungenAusstellungsversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ProduktInformationsblattAusstellungsversicherung,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: ProduktsteckbriefAusstellungsversicherung,
    },
  ],
  SHORT_TERM_INSURANCE: [
    {
      fileName: BedingungenAusstellungsversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ProduktInformationsblattAusstellungsversicherung,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: ProduktsteckbriefAusstellungsversicherung,
    },
  ],
  SAMPLE_COLLECTION_INSURANCE: [
    {
      fileName: BedingungenMusterkollektionsversicherung,
    },
    {
      fileName: ProduktInformationsblattMusterkollektionsversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
  ],
  FAIRGROUND_INSURANCE: [
    {
      fileName: bedingungenLogistikVersicherung,
    },
    {
      fileName: erklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: produktInformationsBlatt,
    },
    {
      fileName: cyberInformationsBlatt,
    },
    {
      fileName: flyerDeclarationManager,
    },
    {
      fileName: flyerFrachtfuhrerHaftungsversicherung,
    },
    {
      fileName: flyerSpeditionsHaftungsversicherung,
    },
  ],
  LUGGAGE_INSURANCE: [
    {
      fileName: BedingungenFirmenReisegepäckversicherung,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ProduktInformationsblattFirmenReisegepäckversicherung,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
  ],
  MARKET_CONCEPT_INSURANCE: [
    {
      fileName: bedingungenLogistikVersicherung,
    },
    {
      fileName: erklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: produktInformationsBlatt,
    },
    {
      fileName: cyberInformationsBlatt,
    },
    {
      fileName: flyerDeclarationManager,
    },
    {
      fileName: flyerFrachtfuhrerHaftungsversicherung,
    },
    {
      fileName: flyerSpeditionsHaftungsversicherung,
    },
  ],
  VALOR_INSURANCE_COMPACT: [
    {
      fileName: BedingungenJuwelierwarenundValorenversicherung,
    },
    {
      fileName: erklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: ProduktInformationsblattJuwelierwarenValorenVersicherung,
    },
    {
      fileName: cyberInformationsBlatt,
    },
    {
      fileName: ProduktsteckbriefJuwelierwarenUndValorenversicherung,
    },
    {
      fileName: TariffürdieVersicherungvonBijouterievaloren,
    },
  ],
  LOGISTICS_INSURANCE: [
    {
      fileName: BedingungenLogistikVersicherung,
    },
    {
      fileName: BesondereBedingungenZoll,
    },
    {
      fileName: CyberInformationsBlatt,
    },
    {
      fileName: ErklarungenUndHinweiseZumAntragAufAbschlussEinerVersicherung,
    },
    {
      fileName: FlyerDigitalesUnderwritingTransportDUTR,
    },
    {
      fileName: HighlightsLogistikVersicherung4,
    },
    {
      fileName: PIBProduktInformationsBlattLogistikVersicherung4,
    },
  ],
};
